import React from "react"
import { Link } from "gatsby"

import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"

const TravelsPage = () => {
	return (
	<Layout>
		<Container>
			<h1 className="text-center mt-5">Bicycle tours</h1>
			<Row className="mt-5 justify-content-center">
				<Col xs={10}>
				<ul>
					<li><Link to="/travels/belgique2018">Belgium 2018</Link></li>
				</ul>
				</Col>
			</Row>
		</Container>
	</Layout>
	);
}

export default TravelsPage
